import React from "react";
import Button from "../button/button";
import css from "./live-view-button.module.scss";
import { useNavigate } from "react-router-dom";

const LiveViewButton = ({
  setVisible
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Button
        label="Live View"
        variant="link"
        onClick={() => {
          // navigate(`/live`);
          setVisible(true);
        }}
      />
    </>
  );
}

export default LiveViewButton;