import React from "react";

import "./not-found.scss";

export function NotFound() {
  return (
    <div className="not-found-container">
      <h1>404 Not Found</h1>
      <a href="/">Back</a>
    </div>
  );
}

export default NotFound;
