import React, { useEffect, useState, useRef, useMemo } from "react";

import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import ThreatSection from "./threat-section";
import { useQuery } from "../../hooks";

import { ENDPOINT } from "../../client";

export default function Threats() {
  useEffect(() => {
    document.title = "MCAP";
  }, []);

  const uri = useMemo(() => {
    const url = new URL("/threats", ENDPOINT);
    return url.toString().replace(ENDPOINT, "");
  }, []);

  const { data, refetch } = useQuery(uri);

  console.log("threats..", data);

  return (
    <>
      <Header />
      <ThreatSection 
        data={data?.data}
      />
      <Footer />
    </>
  );
}