import React, { useEffect, useState } from "react";
import Viewer from "components/viewer/viewer";

const Live = () => {
    useEffect(() => {
      document.title = "MCAP";
    }, []);

    const [showLiveView, setShowLiveView] = useState(true);

    return (
      <>
        <Viewer
        deviceId={"e659c8b7-4cf6-41d9-99ef-0354bc5d4ac9"}
        deviceIP={"10.8.0.33"}
        setShowViewer={setShowLiveView}
        showViewer={showLiveView}
    />
      </>
    );
  };
  
export default Live;