import Hls from 'hls.js';

import Renderer from './Renderer';
import EventHandler from './EventHandler';
import SocketClient from 'SocketClient';

const FRAMES_PER_SECOND = 30;

const RESET_INTERVAL = 3000;

class Streamer {
    constructor() {
        this.resetTimeout = null; // use to check if video is stale
        this.lastFrame = null;
        this.hls = null;

        this.frameTimestamp = null;
        this.framePrevTimestamp = null;

        this.container = null;
        this.deviceIP = null;
        this.videoSrc = null;
        this.canvas = null;

        this.accumulator = 0;
        this.destroyed = false;

        this.renderer = null;
        this.eventHandler = null;

        this.onClick = () => {};
        this.onFatalError = () => {};
        this.onData = () => {};
        this.onFragLoaded = () => {};

        this.shouldDrawBoxes = true;
        this.shouldDrawPoses = true;
        this.shouldDrawTrackIds = true;
        this.shouldDrawTimestamp = true;

        this.hoverId = null;
        this.activeId = null;

        window.requestAnimationFrame(this.onFrameAnimation.bind(this));

        this.listeners = [];

        this.confidence = 1;

        this.socketClient = new SocketClient();

        this.rendererClearTimeout = null;
    }

    set showIds(bool) {
        this.shouldDrawTrackIds = bool;
    }

    set isDebugView(bool) {
        this.shouldDrawBoxes = bool;
        this.shouldDrawPoses = bool;
    }

    zoomIn() {
        if (this.eventHandler) {
            this.eventHandler.zoomIn();
        }
    }

    zoomOut() {
        if (this.eventHandler) {
            this.eventHandler.zoomOut();
        }
    }

    center() {
        if (this.eventHandler) {
            this.eventHandler.center();
        }
    }

    toggleFullscreen() {
        if (this.eventHandler) {
            this.eventHandler.toggleFullscreen();
        }
    }

    frameCoordToCanvas(frame, zoom, offsetX, offsetY) {
        if (!frame?.payload) {
            return;
        }

        const { clientWidth, clientHeight } = this.videoSrc;
        const { frameWidth, frameHeight } = frame.payload;

        return (x, y) => {
            // convert to percent of screen
            const frameX = x / frameWidth;
            const frameY = y / frameHeight;

            // change center of screen to 0, 0
            const relX = (frameX - 0.5) * clientWidth * zoom;
            const relY = (frameY - 0.5) * clientHeight * zoom;

            // scale to screen size and add offsets
            const cameraX = relX + (clientWidth / 2) + offsetX;
            const cameraY = relY + (clientHeight / 2) + offsetY;

            return { x: cameraX, y: cameraY };
        }
    }

    async startHls() {
        if (this.destroyed) return false;

        const url = `https://video.herdsense.com/${this.deviceIP}/index.m3u8`;

        if (Hls.isSupported()) {
            await new Promise((resolve, reject) => {
                this.hls = new Hls({
                  enableWorker: true,
                  maxBufferLength: 1,
                  liveBackBufferLength: 0,
                  liveSyncDuration: 1,
                  liveMaxLatencyDuration: 5,
                  liveDurationInfinity: true,
                  highBufferWatchdogPeriod: 1,
                });

                this.hls.on(Hls.Events.ERROR, (evt, data) => {
                    if (!data.fatal) return;

                    this.hls.destroy();

                    this.onFatalError();
                    reject('could not start stream');
                });

                this.hls.on(Hls.Events.MANIFEST_LOADED, () => {
                    resolve();
                });

                this.hls.on(Hls.Events.FRAG_LOADED, () => {
                    this.onFragLoaded();
                    clearTimeout(this.resetTimeout);
                    this.resetTimeout = setTimeout(() => {
                        this.onFatalError();
                    }, RESET_INTERVAL);
                });

                this.hls.loadSource(url);
                this.hls.attachMedia(this.videoSrc);
            });
        } else if (this.videoSrc.canPlayType('application/vnd.apple.mpegurl')) {
            // since it's not possible to detect timeout errors in iOS,
            // wait for the playlist to be available before starting the stream
            await fetch(url);

            this.videoSrc.src = url;
        }

        return true;
    }

    startSockets(socketClient, deviceId) {
        socketClient.open();
        console.log("Starting web sockets")
        socketClient.subscribe(`device-frame-meta`, deviceId, (data) => {
            console.log("subscribing")
            this.onData(data);

            console.log(data);
            this.lastFrame = data;
            this.framePrevTimestamp = this.frameTimestamp;
            this.frameTimestamp = new Date().getTime();
        });
    }

    stopSockets(socketClient, deviceId) {
        socketClient.unsubscribe('device-frame-meta', deviceId);
    }

    onFrameAnimation(timestamp) {
        if (this.destroyed) {
            return;
        }

        const delta = timestamp - this.prevTimestamp;
        this.prevTimestamp = timestamp;

        this.accumulator += delta;

        if (!this.renderer || this.accumulator < 1000 / FRAMES_PER_SECOND) {
            window.requestAnimationFrame(this.onFrameAnimation.bind(this));
            return;
        }

        this.accumulator = 0;

        this.renderer.clear();

        // this will clear the canvas of rectangles if no payloads
        // have been received for 5 seconds
        if (this.rendererClearTimeout !== null) {
            clearTimeout(this.rendererClearTimeout);
        }
        this.rendererClearTimeout = setTimeout(() => {
            this.renderer.clear();
        }, 5000);

        const frameCoordToCanvasFn = this.frameCoordToCanvas(
            this.lastFrame,
            this.eventHandler.zoom,
            this.eventHandler.offsetX,
            this.eventHandler.offsetY
        );

        const payload = this.lastFrame?.payload;

        this.eventHandler.registerClickBoxes(payload, frameCoordToCanvasFn);

        this.renderer.render(
            payload,
            this.frameTimestamp,
            this.framePrevTimestamp,
            frameCoordToCanvasFn
        );

        window.requestAnimationFrame(this.onFrameAnimation.bind(this));
    }

    async start(deviceIP, container, videoSrc, canvas, deviceId) {
        this.startSockets(this.socketClient, deviceId)
        this.container = container;
        this.deviceIP = deviceIP;
        this.videoSrc = videoSrc;
        this.canvas = canvas;

        let started = false;
        try {
            started = await this.startHls();
            await this.videoSrc.play();
        } catch (err) {
            return false;
        }

        if (!started) {
            return false;
        }

        this.renderer = new Renderer(this);
        this.eventHandler = new EventHandler(this);
        this.eventHandler.start();

        this.listeners.forEach((listener) => {
            const { event, fn } = listener;
            this.videoSrc.addEventListener(event, fn);
        });

        return true;
    }

    stop() {
        this.destroyed = true;

        if (this.renderer) {
            this.renderer.clear();
            this.lastFrame = null;
            this.renderer = null;
        }

        if (this.eventHandler) {
            this.eventHandler.stop();
            this.eventHandler = null;
        }

        if (this.hls) {
            this.hls.destroy();
            this.hls = null;
        }

        if (this.videoSrc) {
            this.listeners.forEach((listener) => {
                const { event, fn } = listener;
                this.videoSrc.removeEventListener(event, fn);
            });
        }
    }
}

export default Streamer;
