const SKELETONS = [
    [0, 1],
    [0, 2],
    [1, 2],

    [1, 3],
    [2, 4],

    [4, 7],
    [6, 8],

    [7, 9],
    [8, 10],

    [11, 13],
    [12, 14],

    [13, 15],
    [14, 16],
];

const POINT_RADIUS = 4;

class Renderer {
    constructor(streamer) {
        console.log('efijoe', streamer)
        this.streamer = streamer;
        this.ctx = this.streamer.canvas.getContext('2d');
    }

    clear() {
        this.ctx.clearRect(0, 0, this.streamer.canvas.width, this.streamer.canvas.height);
    }

    render(payload, timestamp, prevTimestamp, frameCoordToCanvasFn) {
        if (!payload) {
            return;
        }

        // draw lines

        const {clientWidth, clientHeight} = this.streamer.videoSrc;

        // find padding so that canvas fits images 'object-fit: contain;'

        // resize canvas
        this.streamer.canvas.width = clientWidth;
        this.streamer.canvas.height = clientHeight;

        if (this.streamer.shouldDrawPoses) {
            this.drawPoses(payload, frameCoordToCanvasFn);
        }

        if (this.streamer.shouldDrawBoxes) {
            this.drawBoxes(payload, frameCoordToCanvasFn);
        }

        this.drawBoxHighlights(payload, frameCoordToCanvasFn);

        if (this.streamer.shouldDrawTrackIds) {
            this.drawTrackingIds(payload, frameCoordToCanvasFn);
        }

        if (this.streamer.shouldDrawTimestamp) {
            this.drawFrameDeltaInSecs(
                clientWidth,
                clientHeight,
                timestamp,
                prevTimestamp
            );
        }
    }

    stop() {
        this.ctx.clearRect(0, 0, this.streamer.canvas.width, this.streamer.canvas.height);
    }

    drawBoxes(payload, frameCoordToCanvasFn) {

        for (const pose of payload.detections) {
            const {bbox, clsId} = pose;

            this.ctx.beginPath();

            // console.log(pose)
            if (bbox) {
                const {x: startX, y: startY} = frameCoordToCanvasFn(
                    bbox[0],
                    bbox[1]
                );

                const {x: endX, y: endY} = frameCoordToCanvasFn(
                    bbox[2],
                    bbox[3]
                );

                this.ctx.rect(
                    startX,
                    startY,
                    endX - startX,
                    endY - startY
                );
            }

            switch(clsId) {
                case 0:
                    this.ctx.strokeStyle = '#FF0000'
                break;
                case 1:
                    this.ctx.strokeStyle = '#0000FF'
                break;
                case 2:
                    this.ctx.strokeStyle = '#00FFFF'
                break;
            }

            this.ctx.lineWidth = 1;
            this.ctx.stroke();
        }

        // this.ctx.strokeStyle = '#64fe00';
        // this.ctx.lineWidth = 1;
        // this.ctx.stroke();
    }

    drawBoxHighlights(payload, frameCoordToCanvasFn) {
        this.ctx.beginPath();

        for (const pose of payload.detections) {
            const {bbox, trackId, clsId} = pose;

            if (!bbox || !trackId) {
                continue;
            }

            if (this.streamer.hoverId !== trackId && this.streamer.activeId !== trackId) {
                continue;
            }

            const {x: startX, y: startY} = frameCoordToCanvasFn(
                bbox[0],
                bbox[1]
            );

            const {x: endX, y: endY} = frameCoordToCanvasFn(
                bbox[2],
                bbox[3]
            );

            this.ctx.rect(
                startX,
                startY,
                endX - startX,
                endY - startY
            );
        }

        this.ctx.fillStyle = '#0003';
        this.ctx.lineWidth = 1;
        this.ctx.fill();
    }

    drawPoses(payload, frameCoordToCanvasFn) {
        this.ctx.beginPath();

        const { confidence } = this.streamer;

        for (const pose of payload.detections) {
            const {bones} = pose;

            if (!bones) {
                continue;
            }

            let startCor;
            let endCor;
            SKELETONS.forEach((skeleton) => {
                startCor = bones[skeleton[0]];
                endCor = bones[skeleton[1]];

                if (!startCor || !endCor) {
                    return;
                }

                const {x: startAdjX, y: startAdjY} = frameCoordToCanvasFn(
                    startCor.x,
                    startCor.y
                );

                const {x: endAdjX, y: endAdjY} = frameCoordToCanvasFn(
                    endCor.x,
                    endCor.y
                );

                if (startCor.z >= confidence && endCor.z >= confidence) {
                    this.ctx.moveTo(startAdjX, startAdjY);
                    this.ctx.lineTo(endAdjX, endAdjY);
                    return;
                }

                if (startCor.z >= confidence) {
                    this.ctx.moveTo(startAdjX + POINT_RADIUS, startAdjY);
                    this.ctx.arc(startAdjX, startAdjY, POINT_RADIUS, 0, Math.PI * 2);
                    return;
                }

                if (endCor.z >= confidence) {
                    this.ctx.moveTo(endAdjX + POINT_RADIUS, endAdjY);
                    this.ctx.arc(endAdjX, endAdjY, POINT_RADIUS, 0, Math.PI * 2);
                    return;
                }
            });
        }

        this.ctx.strokeStyle = '#64fe00';
        this.ctx.lineWidth = 1;
        this.ctx.stroke();
    }

    drawTrackingIds(payload, frameCoordToCanvasFn) {
        const boxPaddingH = 4;
        const boxPaddingV = 2;
        const fontHeight = 16;

        this.ctx.font = "14px sans-serif";

        for (const pose of payload.detections) {
            const {bbox, trackId, mugId} = pose;

            if (!trackId) {
                continue;
            }

            let [start_x, start_y] = bbox;
            const {x: adjX, y: adjY} = frameCoordToCanvasFn(
                start_x,
                start_y
            );

            const str = `${trackId}, ${mugId || '<null>'}`;
            // const str = mugId ? `${trackId}, ${mugId}` : trackId;

            const strLength = this.ctx.measureText(str).width;

            const boxWidth = strLength + (2 * boxPaddingH);

            this.ctx.beginPath();

            this.ctx.rect(
                adjX,
                adjY,
                boxWidth,
                fontHeight + (2 * boxPaddingV)
            );

            this.ctx.fillStyle = '#0007';

            this.ctx.fill();

            const color = 'white';
            this.ctx.fillStyle = color;
            this.ctx.textAlign = 'left';
            this.ctx.fillText(str, adjX + boxPaddingH, adjY + boxPaddingV + 14);
        }
    }

    drawFrameDeltaInSecs(clientWidth, clientHeight, timestamp, prevTimestamp) {
        if (!timestamp || !prevTimestamp) {
            return;
        }

        const boxPaddingH = 4;
        const boxPaddingV = 2;

        const fontHeight = 16;

        this.ctx.beginPath();

        this.ctx.font = "12px sans-serif";

        const delta = timestamp - prevTimestamp;

        const deltaStr = `${(delta / 1000).toFixed(2)}s`;
        const strLength = this.ctx.measureText(deltaStr).width;

        const boxWidth = strLength + (2 * boxPaddingH);

        this.ctx.rect(
            clientWidth - boxWidth,
            0,
            boxWidth,
            fontHeight + (2 * boxPaddingV)
        );

        this.ctx.fillStyle = '#000a';
        this.ctx.fill();

        this.ctx.fillStyle = 'white';
        this.ctx.textAlign = 'right';
        this.ctx.fillText(deltaStr, clientWidth - boxPaddingH, fontHeight);
    }
}

export default Renderer;
