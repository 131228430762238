import css from "./header.module.scss";

export default function Header({

}) {
  return (
    <>
      <nav className={css.navbar}>
        <p className={css.mcap}>MCAP</p>
      </nav>
    </>
  );
}