import React, { useEffect, useState, useRef, useMemo } from "react";
import { ENDPOINT } from "../../client";
import { useQuery } from "../../hooks";
import css from "./threat-section.module.scss";
import Threatbox from "./sections/threatbox/threatbox";
import { Dialog } from 'primereact/dialog';
import Viewer from "components/viewer/viewer";

export default function ThreatSection(props) {

  // state
  // const [visible, setVisible] = useState(false);
  const [showLiveView, setShowLiveView] = useState(false);
  const liveVideo = "blob:https://demos.themainbranch.com/86306063-6582-4875-9f7e-e3afbc74a87f";

  const uri = useMemo(() => {
    const url = new URL("/threats", ENDPOINT);
    return url.toString().replace(ENDPOINT, "");
  }, []);

  const {
    data = [],
  } = props;

  const modalLive = (
    <Viewer
      deviceId={"e659c8b7-4cf6-41d9-99ef-0354bc5d4ac9"}
      deviceIP={"10.8.0.33"}
      setShowViewer={setShowLiveView}
      showViewer={showLiveView}
  />
  );

  return (
    <div className={css.threatSection}>
      {data.map((datum) => (
        <Threatbox
          key={datum.id}
          data={datum}
          setVisible={setShowLiveView}
        />
      ))}

      {modalLive}
    </div>
  );
};