import {
  appFonts,
  borderRadius,
  // darken,
  flexbox,
  fontbox,
  paddingX,
  paddingY,
  transition,
} from "emotion/utils";

const getBtnBgColor = (theme, color, variant) => {
  // console.log("theme", theme?.button?.[color]?.[variant]?.background);
  // console.log('color', color);
  // console.log('variant', variant);

  const backgroundColor = theme?.button?.[color]?.[variant]?.background;

  const borderColor = theme?.button?.[color]?.[variant]?.border;

  const clr = theme?.button?.[color]?.[variant]?.color;

  return { backgroundColor, borderColor, clr };
};

export const css = (theme, color, variant) => {
  const jc = variant === "link" ? "flex-start" : "center";
  const padX = variant === "link" ? 0 : 16;
  const padY = variant === "link" ? 0 : 10;
  // const hoverLink =
  //   variant === "link"
  //     ? { color: darken(getBtnBgColor(theme, color, variant).clr, -0.15) }
  //     : {
  //         backgroundColor: darken(
  //           getBtnBgColor(theme, color, variant).backgroundColor,
  //           -0.15,
  //         ),
  //       };

  return {
    ...borderRadius(8),
    ...flexbox("row", "center", jc, 6),
    ...fontbox(appFonts.inter, 14, 600, 22),
    ...paddingX(padX),
    ...paddingY(padY),
    ...transition(),
    backgroundColor: getBtnBgColor(theme, color, variant).backgroundColor,
    border:
      variant !== "link"
        ? `1px solid ${getBtnBgColor(theme, color, variant).borderColor}`
        : "none",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    color: getBtnBgColor(theme, color, variant).clr,
    cursor: "pointer",

    "@media screen and (min-width: 576px)": {
      ...fontbox(appFonts.inter, 16, 600, 24),
    },

    // "&:hover": {
    //   ...hoverLink,
    // },

    container: {
      // backgroundColor:
      //   theme?.button?.[color]?.background ||
      //   theme?.button?.primary?.background,
      // border: `1px solid ${
      //   theme?.button?.[color]?.border || theme?.button?.primary?.border
      // }`,
      // color: theme?.button?.[color]?.color || theme?.button?.primary?.color,

      "&:hover": {
        // backgroundColor: darken(theme?.button?.[color]?.background, -0.25),
      },

      link: {
        // ...borderRadius(0),
        // ...paddingX(0),
        // ...paddingY(0),
        // backgroundColor: "transparent",
        // border: "none",
        // boxShadow: "unset",
        // // color: theme?.button?.[color]?.border || theme?.button?.primary?.border,
        // flex: "unset",
        // marginRight: "auto",
        // width: "auto",

        "&:hover": {
          // backgroundColor: "transparent",
          // // color: darken(theme?.button?.[color]?.border, -0.25),
        },
      },
    },
  };
};
