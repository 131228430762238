import {w3cwebsocket} from 'websocket';

const { REACT_APP_SOCKET_URL } = process.env;

let socketEndpoint = `ws://localhost:3001`;
if (REACT_APP_SOCKET_URL) {
  socketEndpoint = REACT_APP_SOCKET_URL;
}

class SocketClient {
  constructor() {
    this.client = null;
    this.isOpen = false;
    this.keepOpen = false;
    this.loading = false;
    this.subscriptions = new Map();
  }

  update(subscription, id, cb) {
    const subTag = `${subscription}/${id}`;

    let existingSub = this.subscriptions.get(subTag);
    if (!existingSub || !this.isOpen) {
      return;
    }

    existingSub.cb = cb;
  }

  subscribe(subscription, id, cb) {
    const subTag = `${subscription}/${id}`;

    let existingSub = this.subscriptions.get(subTag);
    if (!existingSub) {
      existingSub = {
        subscription,
        id,
        cb
      };

      this.subscriptions.set(subTag, existingSub);
    }

    if (!this.isOpen) return;

    console.log("sending", existingSub)
    this.client.send(JSON.stringify({
      operation: 'subscribe',
      ...existingSub
    }));
  }

  unsubscribe(subscription, id) {
    const subTag = `${subscription}/${id}`;

    const existingSub = this.subscriptions.get(subTag);
    if (existingSub) {
      this.subscriptions.delete(subTag);

      if (!this.isOpen) return;

      const obj = {
        operation: 'unsubscribe',
        ...existingSub
      };
      this.client.send(JSON.stringify(obj));
    }
  }

  open() {
    if (this.isOpen || this.loading) return;

    this.loading = true;

    this.client = new w3cwebsocket(socketEndpoint);
    this.client.onopen = () => {
      console.log('sockets open');
      this.loading = false;
      this.isOpen = true;
      this.keepOpen = true;
      this.subscriptions.forEach((s) => {
        this.subscribe(s.subscription, s.id, s.cb);
      });
    };

    this.client.onclose = () => {
      console.log('sockets restarting');
      this.isOpen = false;
      this.timeout = setTimeout(() => {
        this.open();
      }, 2000);
    };

    this.client.onmessage = (msg) => {
      console.log("msg", msg)
      const { subscription, data } = JSON.parse(msg.data);
      const sub = this.subscriptions.get(subscription);
      if (sub?.cb) {
        sub.cb(data);
      }
    };
  }

  close() {
    if (!this.isOpen) return;

    this.isOpen = false;
    this.keepOpen = false;
    this.client.onclose = () => {};
    this.client.close();
    clearTimeout(this.timeout);
    console.log('sockets closed');
  }
}

export default SocketClient;
