import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { appThemes } from "emotion/utils";
import Threats from "./pages/threats";
import Live from "./pages/liveView"
import { NotFound } from "./pages/not-found/not-found";
import { ErrorBoundary } from "./components/error-boundary/error-boundary";
import { useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ThreatView from "pages/threat-view/threat-view";

function App() {
  const [thm, setThm] = useState("light");

  return (
    <ThemeProvider theme={appThemes(thm)}>
      <ErrorBoundary>
         <BrowserRouter>
          <QueryClientProvider client={new QueryClient()}>
            <Routes>
              <Route path="/" element={<Threats />} />
              <Route path="/live" element={<Live />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </QueryClientProvider>
         </BrowserRouter>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;