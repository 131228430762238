import React from 'react';
// import {Modal} from 'react-bootstrap';
import {useQuery} from '@tanstack/react-query';
import Client from '../../client'
import { Dialog } from 'primereact/dialog';

import Canvas from './index';

import css from './styles.module.css';

const Viewer = ({
    setShowViewer,
    deviceId,
    deviceIP,
    showViewer,
    liveViewMode = 'websocket',
}) => {
    const {data: devicesRsp} = useQuery({
        queryKey: ['all_devices'],
        queryFn: () => Client.get(`/devices`),
        refetchOnWindowFocus: false,
    });

    const devices = (devicesRsp || {}).body || [];

    const modalBody = (
        <Canvas
            className={css.modalCanvas}
            deviceId={deviceId}
            deviceIP={deviceIP}
            videoClassName={css.modalVideo}
        />
    );

    return (
        // <Modal
        //     size="xl"
        //     centered
        //     show={showViewer}
        //     onHide={() => setShowViewer(false)}>
        //     <Modal.Header
        //         style={{justifyContent: 'flex-start'}}
        //         closeButton
        //         className="p-3">
        //     </Modal.Header>
        //     {modalBody}
        // </Modal>
        <Dialog maximizable header="Header" visible={showViewer} style={{ width: '50vw' }} onHide={() => {if (!showViewer) return; setShowViewer(false); }}>
          {modalBody}
        </Dialog>
    );
};

export default Viewer;
